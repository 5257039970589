import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../Components/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    // State to manage loading and submission status
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Initialize AOS
    useEffect(() => {
        Aos.init({
            duration: 1000,
            delay: 1000,
            once: true,
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate inputs
        const name = e.target.name.value;
        const email = e.target.email.value;
        const comment = e.target.comment.value;

        if (!name || !email || !comment) {
            toast.warning('Please fill out all fields.');
            return;
        }

        setLoading(true);

        try {
            const result = await emailjs.sendForm(
                'service_lasrivr', // Replace with your EmailJS service ID tuker service / template
                'template_ixavyts', // Replace with your EmailJS template ID 
                e.target,
                'AuCBJH6CVjl7ckWar' // Replace with your EmailJS public user ID
            );
            console.log('Email sent successfully:', result);
            toast.success('Email sent successfully!');
            e.target.reset(); // Reset the form fields
            setIsSubmitted(true); // Set the submitted state to true
            setTimeout(() => setIsSubmitted(false), 60000); // Re-enable the form after 60 seconds
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error(`Error sending email: ${error.text || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="bg-gray-900 w-full h-screen py-16 overflow-y-hidden">
                <div className="container mx-auto p-4 lg:p-8">
                    <hr className="w-14 lg:w-20 border-2 border-[#29ADB2]" />
                    <h1 className="text-start text-[#29ADB2] text-[1.7rem] font-bold">Contact</h1>
                    <div className="flex justify-center items-center">
                        <div className="bg-gray-800/50 shadow-sky-500 w-full md:w-[80%] lg:w-[60%] rounded-md shadow-md mt-8 p-4 lg:p-8" data-aos="fade-down">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="name" className="flex text-sky-300 justify-start mb-4">Name</label>
                                    <input type="text" id="name" name="name" placeholder="Name" className="rounded-lg w-full p-2 mb-2" />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email" className="flex text-sky-300 justify-start mb-4">Email</label>
                                    <input type="email" id="email" name="email" placeholder="example@example.com" className="rounded-lg w-full p-2 mb-2" />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="comment" className="flex text-sky-300 justify-start mb-4">Comment</label>
                                    <textarea id="comment" name="comment" placeholder="Your Comment" className="rounded-lg w-full max-h-56 p-2 overflow-y-hidden mb-2" />
                                </div>
                                <button
                                    type="submit"
                                    className="bg-sky-600 hover:bg-sky-700 text-white rounded-lg py-2 px-4"
                                    disabled={loading || isSubmitted} // Disable the button if loading or just submitted
                                >
                                    {loading ? 'Sending...' : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
};

export default Contact;
