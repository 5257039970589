import React from 'react';
import Kucing from '../Assets/images/kucingKetawa.mp4'

const NotFound = () => {
    return (
        <div className="flex flex-col justify-center items-center w-full h-screen bg-gray-900 text-white">
            <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold mb-4">404</h1>
            <p className="text-lg md:text-xl lg:text-2xl text-center">Halaman yang kamu cari tidak ditemukan.</p>
            
            {/* Menambahkan video auto-play */}
            <video
                autoPlay
                loop
                muted
                className="mt-8 w-48 md:w-64 lg:w-96"
            >
                <source src={Kucing} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            
            <a href="/" className="text-blue-500 hover:underline mt-4">Kembali ke Beranda</a>
        </div>
    );
};

export default NotFound;
