import React, { useEffect } from 'react';
import Navbar from '../Components/navbar';
import heroImg from '../Assets/images/Asset 3.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Typed from 'typed.js';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 700,
      once: true,
    });

    const typed = new Typed('.typing', {
      strings: ["Hi, I'm Angga"],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      delay: 1000,
      loop: true,
      showCursor: false,
    });

    document.querySelectorAll('[data-aos="fade-typing"]').forEach((element) => {
      element.addEventListener('aos:in', () => {
        typed.start();
      });
    });

    return () => {
      typed.stop();
    };

  }, []);
  return (
    <>
      <Navbar />
      <div className="w-full h-full md:h-screen bg-gray-900 p-4 lg:p-8 py-20 lg:py-16 xl:py-24 md:overflow-y-hidden">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {/* Hero */}
            <div className="order-first flex justify-center lg:justify-center md:order-first">
              {/* Wrap the image with Fade component */}
                <img src={heroImg} alt="" className="w-[250px] md:w-[350px] md:pt-8 md:h-[400px] lg:w-[400px] lg:h-full" data-aos="fade-left" />
            </div>
            {/* Heading */}
            <div className="pt-8 lg:p-8">
              <hr className="w-14 lg:w-14 flex flex-wrap justify-end ml-auto border-2 border-[#29ADB2]"/>
              {/* Wrap each heading with Fade or Slide component */}
              <div className="h-10">
                <p className="text-[1.3rem] md:text-[1.7rem] lg:text-[1.5rem] xl:text-[2rem]  font-semibold flex flex-wrap justify-end ml-auto text-white font-mono typing" data-aos="fade-typing"></p>
              </div>
                <h1 className="text-[1.8rem] md:text-[3rem] lg:text-[2.2rem] xl:text-[2.5rem] flex justify-end font-bold text-sky-500 "data-aos="fade-up">Junior Web<span className="ml-4 text-[#29ADB2]" data-aos="fade-right">Developer</span></h1>
                <p className="text-[1.1rem] md:text-[1.8rem] md:leading-10 lg:text-[1.3rem] text-justify lg:text-end py-4 font-thin text-white leading-10" data-aos="fade-right">Hi everyone! Allow me to introduce myself, my name is Johana Angga Rachmadi. People usually call me JO, Angga, or Rahmed. I recently graduated from the Indonesian Digital Technology University, majoring in Computer Technology under the Information Technology study program. I am now a fresh graduate actively seeking job opportunities.</p>
                <div className="flex justify-center md:justify-end pb-8 md:pb-0">
                  <Link to="/contact">
                    <button data-aos="fade-right" className="bg-sky-600 md:text-[1.5rem] lg:text-[1.2rem] hover:bg-sky-700 flex text-white font-semibold p-2 rounded-lg transition-all transform hover:scale-110 duration-300">Hire me :)</button>
                    </Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
