import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../Components/navbar';

const experiences = [
  {
    title: 'Full-Stack Web Developer',
    company: 'NaZMa Office',
    year: 'Feb 2024 - Jun 2024',
    description:
      'Internship as a FullStack Web Developer for 4 months',
  },
  {
    title: 'Front-end Web Developer',
    company: 'Cloufina Nata Karya',
    year: 'Sep 2023 - Jan 2024',
    description:
      'Internship as a FrontEnd Developer for 4 months',
  },
  {
    title: 'Staff Multitalent',
    company: 'MX KOMPUTER JOGJA',
    year: 'Jun 2021 - Sep 2021',
    description:
      'Internship as Multitalented Staff at a Computer Shop for 3 months',
  },
  {
    title: 'Staff Multitalent',
    company: 'Cpit Compushop',
    year: 'Jan 2020 - Mar 2020',
    description:
      'Internship as Multitalented Staff at a Computer Shop for 3 months',
  },
];

const Experience = () => {
  useEffect(()=> {
    Aos.init({
      duration: 1000,
      delay: 200,
      once: false,
    });
  }, []);
  return (
    <>
    <Navbar />
    <div className="bg-gray-900  w-full overflow-y-auto py-16">
            <div className="container mx-auto p-4 lg:p-8">
            <hr className="w-14 lg:w-20 border-2 border-[#29ADB2]"/>
            <h1 className="text-start text-[#29ADB2] text-[1.7rem] font-bold">Experience</h1>
          <div className="grid grid-cols-1 py-2">
            <div className="grid gap-4 order-2 md:order-last">
              {experiences.map((experience, index) => (
                <div
                key={index}>
                  <span className='flex justify-center items-center'>
                    <div className="grid grid-cols-1">
                  <div className='w-1 h-28 ml-1 bg-gradient-to-t from-sky-500 to-emerald-200 mb-4' data-aos="fade"></div>
                  <div className='w-3 h-3 rounded-full bg-gradient-to-t from-blue-500 to-emerald-200 mb-4' data-aos="fade-down"></div>
                    </div>
                  </span>
                  <div className="w-auto flex justify-center items-center">
                    <div className="grid grid-cols-1">
                  <h1 className="font-Lugrasimo md:text-[1.7rem] bg-blue-500 text-center text-blue-100 rounded-lg font-bold text-lg lg:text-lg md:py-2 md:px-4 lg:p-1" data-aos="fade-up">{experience.title}</h1>
                  <p className="font-mono md:text-[1.5rem] mb-2 mt-2 text-white" data-aos="fade-up">{experience.company}</p>
                  <p className="mb-2 text-white md:text-[1.5rem] lg:text-[1.3rem]" data-aos="fade-up">{experience.year}</p>
                  <p className="text-[0.9rem] md:text-[1.5rem] lg:text-[1rem] text-white" data-aos="fade-up">{experience.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <p className="text-[1.3rem] md:text-[2rem] text-white lg:text-[1.7rem] md:py-4 font-bold">
              Here's my work history:
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
