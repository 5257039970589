// import Gambar from '../Assets/images/Asset-5.png'
import CrudImg from '../Assets/icons/table.png';
import FakeApi from '../Assets/icons/ecommerce.png';
import FilmImg from '../Assets/icons/website.png';
import LandingPageImg from '../Assets/icons/graph.png';
import CardProject from '../Components/cardProject';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Navbar from '../Components/navbar';
import reactlogo from '../Assets/icons/react-svgrepo-com.svg'
import tailwind from '../Assets/icons/tailwind-svgrepo-com.svg'
import laravel from '../Assets/icons/laravel-svgrepo-com.svg'
import bootstrap from '../Assets/icons/bootstrap-svgrepo-com.svg'
import html from '../Assets/icons/html-5-svgrepo-com.svg'
import css from '../Assets/icons/css-3-svgrepo-com.svg'
import js from '../Assets/icons/js-svgrepo-com.svg'
import php from '../Assets/icons/php-svgrepo-com.svg'
import java from '../Assets/icons/java-svgrepo-com.svg'
import kotlin from '../Assets/icons/kotlin-1-logo-svgrepo-com.svg'
import reactGroq from '../Assets/icons/reactGroq.png'



const Project = () => {
  useEffect(()=> {
    Aos.init({
      duration: 1000,
      delay: 1000,
      once: false,
    });
  }, []);
  
    const cards = [
        {
          image: CrudImg,
          title: 'Table Crud',
          patch: "https://anggarchmdi-project1.netlify.app",
        },
        {
          image: FakeApi,
          title: 'E-Commerce',
          patch: "https://anggarchmdi-project2.netlify.app/",
        },
        {
          image: FilmImg,
          title: 'Film',
          patch: "https://anggarchmdi-project3.netlify.app",
        },
        {
          image: LandingPageImg,
          title: 'Landing Page',
          patch: "https://rchmdi.me",
        },
        {
          image: reactGroq,
          title: 'React | Groq AI',
          patch: "https://heyjo-ai.netlify.app/",
        },
      ];
    return(
        <>
        <Navbar />
        <div className="bg-gray-900 w-full py-16 lg:overflow-y-hidden hidden xl:block">
            <div className="container mx-auto p-4 lg:p-8">
            <hr className="w-14 lg:w-20 border-2 border-[#29ADB2]"/>
            <h1 className="text-start text-[#29ADB2] text-[1.7rem] font-bold">Project</h1>
            <div className="flex flex-wrap-reverse justify-between ml-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 py-2">
                {/* text */}
                <div className="">
                  <p className='flex justify-center text-white md:justify-start md:text-[1.2rem] mt-8 mb-4' data-aos="fade-left">The following is a ReactJS project I created:</p>
               {/* card */}
                <div className="shadow-cyan-600 shadow-inner bg-gray-800 rounded-lg h-[400px] overflow-y-auto" data-aos="fade-left">
               <div className=" flex flex-col pt-4 ml-8 mr-8">  
               <div className="grid grid-cols-2 gap-8 mb-4">
                {cards.map((card, index) => (
                  <CardProject key={index} image={card.image} title={card.title} patch={card.patch}
                  data-aos="fade-right" />
                ))}
              </div>
               </div>
               </div>
                </div>

                {/* image */}
                <div className="order-first lg:order-last md:mt-0 lg:ml-32">
                    {/* <img src={Gambar} alt="gambar" className='w-[400px] ml-auto mr-20'data-aos="fade-right"/> */}
                    <p className='flex justify-start text-white md:justify-start md:text-[1.2rem] mb-4' data-aos="fade-right">The programming language I use :</p>
                    <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-4 py-4 md:space-x-6 lg:space-x-0">
                      <div className=" " >
                        <img src={html} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className=" ">
                        <img src={css} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className=" ">
                        <img src={js} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className="md:mt-">
                        <img src={php} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className="md:mt-2">
                        <img src={java} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className="mt-8 md:mt-5">
                        <img src={kotlin} className='w-28 md:w-16' alt="" data-aos="fade-right" />
                        </div>            
                    </div>

                    <p className='flex justify-start text-white md:justify-start md:text-[1.2rem] mb- mt-8' data-aos="fade-right">The Framework I use :</p>
                    <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-4 py-4 md:space-x-6">
                      <div className=" " >
                        <img src={reactlogo} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className=" ">
                        <img src={tailwind} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className=" ">
                        <img src={bootstrap} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>            
                      <div className="">
                        <img src={laravel} className='w-36 md:w-20' alt="" data-aos="fade-right" />
                        </div>                       
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>

        {/* mobile */}
        <div className="bg-gray-900 w-full h-screen py-16 overflow-y-auto lg:overflow-y-hidden xl:hidden">
            <div className="container mx-auto p-4 lg:p-8">
            <hr className="w-14 lg:w-20 border-2 border-[#29ADB2]"/>
            <h1 className="text-start text-[#29ADB2] text-[1.7rem] font-bold">Project</h1>
            <div className="flex flex-wrap-reverse justify-between ml-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 py-2">
                {/* text */}
                <div className="">
                  <p className='flex justify-center text-white md:justify-start text-[1.2rem] mt-8 mb-4' data-aos="zoom-in-up">The following is a ReactJS project I created:</p>
               {/* card */}
                <div className="shadow-cyan-600 shadow-inner bg-gray-800 rounded-lg h-[400px] overflow-y-auto" data-aos="zoom-in-up">
               <div className=" flex flex-col pt-4 ml-8 mr-8">  
               <div className="grid grid-cols-2 gap-8 mb-4">
                {cards.map((card, index) => (
                  <CardProject key={index} image={card.image} title={card.title} patch={card.patch}
                  data-aos="fade-right" />
                ))}
              </div>
               </div>
               </div>
                </div>

                {/* image */}
                <div className="order-first lg:order-last md:mt-0 lg:ml-32">
                    {/* <img src={Gambar} alt="gambar" className='w-[400px] ml-auto mr-20'data-aos="fade-right"/> */}
                    <p className='flex justify-start text-white md:justify-start text-[1.2rem] mb-4' data-aos="fade-right">The programming language I use :</p>
                    <div className="grid grid-cols-4 md:grid-cols-6 gap-y-4 lg:grid-cols-4 py-4 md:space-x-6 lg:space-x-0">
                      <div className=" " >
                        <img src={html} className='w-14 md:w-20' alt="" data-aos="zoom-in-left" />
                        </div>            
                      <div className=" ">
                        <img src={css} className='w-14 md:w-20' alt="" data-aos="zoom-in-left" />
                        </div>            
                      <div className=" ">
                        <img src={js} className='w-14 md:w-20' alt="" data-aos="zoom-in-right" />
                        </div>            
                      <div className="md:mt-">
                        <img src={php} className='w-14 md:w-20' alt="" data-aos="zoom-in-right" />
                        </div>            
                      <div className="md:mt-2">
                        <img src={java} className='w-14 md:w-20' alt="" data-aos="zoom-in-left" />
                        </div>            
                      <div className="mt-4 md:mt-5">
                        <img src={kotlin} className='w-10 md:w-16' alt="" data-aos="zoom-in-left" />
                        </div>            
                    </div>

                    <p className='flex justify-start text-white md:justify-start text-[1.2rem] mb- mt-8' data-aos="zoom-in-up">The Framework I use :</p>
                    <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-4 py-4 md:space-x-6">
                      <div className=" " >
                        <img src={reactlogo} className='w-14 md:w-20' alt="" data-aos="zoom-in-left" />
                        </div>            
                      <div className=" ">
                        <img src={tailwind} className='w-14 md:w-20' alt="" data-aos="zoom-in-left" />
                        </div>            
                      <div className=" ">
                        <img src={bootstrap} className='w-14 md:w-20' alt="" data-aos="zoom-in-right" />
                        </div>            
                      <div className="">
                        <img src={laravel} className='w-14 md:w-20' alt="" data-aos="zoom-in-right" />
                        </div>                       
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </>
    )
}
export default Project;