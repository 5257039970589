import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const Links = [
    { name: "HOME", link: "/" },
    { name: "ABOUT", link: "/about" },
    { name: "EXPERIENCE", link: "/experience" },
    { name: "PROJECT", link: "/project" },
    { name: "CONTACT", link: "/contact" },
  ];
  const [open, setOpen] = useState(false);
  return (
    <div className='shadow-md w-full fixed top-0 right-0 z-50'>
      <div className='lg:flex items-center justify-between bg-gray-900 bg-opacity-70 backdrop-blur-lg p-6 md:p-4 md:px-10 px-7'>
        <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
          <span className='text-3xl text-indigo-600 pt-2 flex items-center'>
          </span>
          <h1 className='text-sky-500 text-[1.2rem] md:text-[1.5rem]'>
            MY PORTFOLIO
          </h1>
        </div>
        {/* tombol */}
        <div onClick={() => setOpen(!open)} className='text-3xl absolute text-white right-8 top-6 cursor-pointer lg:hidden'>
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>
        
        <ul className={`lg:flex w-36 md:w-1/3 mt-5 lg:mt-0 h-screen lg:h-0 md:items-center lg:pb-0 pb-2 absolute lg:static bg-slate-900 bg-opacity-95 lg:bg-transparent backdrop-filter lg:z-auto -z-30 right-0 lg:w-auto md:pl-0 pl-4 transition-all duration-500 ease-in ${open ? 'right-0' : 'right-[-500px]'}`}>
          {
            Links.map((link) => (
              <li key={link.name} className='text-start md:ml-8 text-xl lg:my-0 my-6'>
                <a 
                  href={link.link} 
                  className={`text-[1rem] md:text-[1.5rem] lg:text-[1rem] ${location.pathname === link.link ? 'text-sky-300 font-bold' : 'text-sky-500'} hover:text-sky-300 duration-500 lg:font-Lugrasimo`}
                >
                  {link.name}
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
